import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
  message: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<DeleteDialogComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.message = this.data != null && this.data.message.length > 0 ? this.data.message : 'Are you sure want to delete?'
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
