import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-p ermissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.css']
})
export class UserPermissionsComponent implements OnInit {

  user_permission_modules = [
    {
      // "Mice": {
      //   en
      // }
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
