import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviourService {
  private header$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor() { }

  setHeader(header) {
    this.header$.next(header);
  }

  getHeader(): Observable<boolean> {
    return this.header$.asObservable();
  }
}
