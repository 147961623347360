// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  packageTypes: [
      {id: 1, name: "Honeymoon"},
      {id: 2, name: "Water Sport"},
      {id: 3, name: "Family"}
  ],

  CONTACT_EMAIL: 'info@mountainedge.in / mountainedgepb@gmail.com',
  CONTACT_ADDRESS: '1st Floor, Firdos Manzil, Near Clock Tower, Aberdeen Bazaar, Port Blair - 744101, Andaman Islands',
  ADDRESS_FIRST:'1st Floor, Firdos Manzil, Near Clock Tower, Aberdeen Bazaar,',
  ADDRESS_SECOND:'Port Blair - 744101, Andaman Islands',
  TEL_NO: '03192-233725 / 232228',
  MOB_NO: '+91 800-1221-800',
  CONTACT_WEBSITE: 'https://www.mountainedge.in',
  CRM_NAME: 'ME Group CRM',
  COMPANY_NAME: 'Mountain Edge Tours and Holidays Pvt. Ltd.',
  LOGO: 'assets/img/logo.png',
  INVOICE_LOGO:'assets/img/Mountain-Edge.png',
  DIRECTOR: 'Mr Prakash Kumar Raj',
  SORT_COMPANY_NAME: 'Mountain Edge',

  SERVICE_CONTACT_EMAIL: 'info@mountainedge.in / mountainedgepb@gmail.com',
  SERVICE_CONTACT_ADDRESS: 'Near postal and AHW colony, opposite rajasthan manch shadipur post, Port Blair south andaman nicobar island 744106',
  SERVICE_ADDRESS_FIRST:'Near postal and AHW colony, opposite rajasthan manch shadipur post,',
  SERVICE_ADDRESS_SECOND:'Port Blair south andaman nicobar island 744106',
  SERVICE_TEL_NO: '03192-233725 / 232228',
  SERVICE_MOB_NO: '+91 800-1221-800',
  SERVICE_CONTACT_WEBSITE: 'https://www.mountainedge.in',
  SERVICE_CRM_NAME: 'ME Group CRM',
  SERVICE_COMPANY_NAME: 'Mountain Edge Business Services',
  SERVICE_LOGO: 'assets/img/service_logo.png',
  SERVICE_INVOICE_LOGO:'assets/img/service_logo.png',
  SERVICE_DIRECTOR: 'Mr Prakash Kumar Raj',
  SERVICE_SORT_COMPANY_NAME: 'Mountain Edge',

  TRIP_ZOLO_INVOICE_LOGO:'assets/img/tripzolo-logo.png',
  TRIP_ZOLO_CIN_NO:'U6303MH2020PTC346749',
  TRIP_ZOLO_UAN:'assets/img/tripzolo-logo.png',
  TRIP_ZOLO_SAC_CODE:'998596',
  TRIP_ZOLO_GSTIN:'27AAICT1244J1Z8',
  TRIP_ZOLO_ADDRESS:'B-701, Sagar Tech Plaza, Andheri Kurla Road, Sakinaka, Mumbai-400072, Maharashtra, India',
  TRIP_ZOLO_TEL_NO: '022 46021672 | +91 7777-02-4800',
  TRIP_ZOLO: 'Tripzolo India Pvt. Ltd.',
  TRIP_ZOLO_EMAIL: 'contact@tripzolo.com | tripzoloindia@gmail.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
