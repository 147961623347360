import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EndPointService } from 'src/app/services/end-point.service';
import { ResponseToasterService } from 'src/app/services/responseStatus/response-toaster.service';
import { PlaceDialogComponent } from '../place-dialog/place-dialog.component';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.css']
})
export class PlaceComponent implements OnInit {
  countries = [];
  states = [];
  location_data = [];

  country: any;
  state: any;
  location: any;

  country_btn: boolean = false;
  state_btn: boolean = false;
  location_btn: boolean = false;
  countryLoader: boolean = false;

  constructor(
    private endpoint: EndPointService,
    public toastr: ResponseToasterService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  loadCountries(){
    if(this.countries.length <= 0){
      this.countryLoader = true;
      this.endpoint.getData('place/countries', {}).subscribe((resp) => {
        if (resp.status && resp.message == 'success') {
          this.countryLoader = false;
          this.countries = resp.data;
        }
      },(err) => {
        this.toastr.errorResponse('Oops.!<br> Error while load countries', true);
      });
    }
  }

  onCountryChange(country){
    this.country_btn = true;
    this.state_btn = false;
    this.location_btn = false;
    this.location_data = [];
    this.country = {id: country.value, name:country.source.triggerValue};
    this.getStates(country.value);
  }

  getStates(country_id){
    this.endpoint.getData('place/countries/states/'+country_id, {}).subscribe((resp) => {
        if (resp.status && resp.message == 'success') {
          this.states = resp.data;
        }
      },(err) => {
        this.toastr.errorResponse('Oops <br>Error while fetching states.')
      }
    );
  }

  onStateChange(state) {
    this.state_btn = true;
    this.location_btn = false;
    this.state = {id: state.value, name:state.source.triggerValue};
    this.getLocations(state.value);
  }

  getLocations(state_id){
    this.endpoint.getData('place/state/locations/' + state_id, {}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.location_data = resp.data;
        }
      },(err) => {
        alert('Oops :)\nError while fetching enquiries.');
        this.toastr.errorResponse('Oops <br>nError while fetching locations.')
      }
  );
  }

  onLocationsChange(location){
    this.location = {id: location.value, name:location.source.triggerValue};
    this.location_btn = true;
  }

  addUpdatePlace(place, add = false){
    let dialogRef: any;
    switch(place){
      case 'country':
        dialogRef = this.dialog.open(PlaceDialogComponent, {data: {placeType:'country', params: (add == false) ?this.country: {add_new: add} }});
      break;
      case 'state':
        dialogRef = this.dialog.open(PlaceDialogComponent, {data: {placeType:'state', params: (add == false) ? this.state : {add_new: add, country_id: this.country.id} }});
      break;
      case 'location':
        dialogRef = this.dialog.open(PlaceDialogComponent, {data: {placeType:'location', params: (add == false) ? this.location : {add_new: add, state_id: this.state.id}}});
      break;
    }

    dialogRef.afterClosed().subscribe(value => {
      if(value != "" && value != undefined){
        switch(place){
          case 'country':
            this.countries = [];
            this.loadCountries();
            if(add == false){
              this.country = {id: value.data.id, name:value.data.name};
            }
            break;
            case 'state':
            this.getStates(this.country.id);
            if(add == false){
              this.state = {id: value.data.id, name:value.data.name};
            }
            break;
            case 'location':
            this.getLocations(this.state.id);
            if(add == false){
              this.location = {id: value.data.id, name:value.data.name};
            }
            break;
          }
console.log("sdfdsf"+value);
      }
    });
  }
}
