import { Component, OnInit } from '@angular/core';
import { EndPointService } from 'src/app/services/end-point.service';

@Component({
  selector: 'app-mice-mark-up',
  templateUrl: './mice-mark-up.component.html',
  styleUrls: ['./mice-mark-up.component.css']
})
export class MiceMarkUpComponent implements OnInit {

  markup_btn: boolean = false;
  markups = [];
  selected_type = "update";
  markup = {
    id: 0,
    markup_on: '',
    markup_rate: 0
  }
  constructor(
    private endPoint: EndPointService,
    // private _snack_bar: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.getMarkup();
  }

  getMarkup(){
    this.endPoint.getData("mice-markups", {}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
            this.markups = resp.data;
          return;
        }
      },
      (err) => {
        alert("Error while fetching markups.");
      }
    );
  }

  onMarkup(){
    this.endPoint.postData("mice-markups", this.markup, false).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          alert(this.markup.id > 0 ? "Successfully updated." : "Successfully created.");
          if(this.markup.id == 0){
            this.markups.push(resp.data);
          }
          this.addUpdateMarkup('', 'update');
          return;
        }
        alert(resp.message);
      },
      (err) => {
        alert("Error while create/update markup.");
      }
    );
  }

  addUpdateMarkup(markup, type){
    this.selected_type = type;
    if(type == 'add'){
      this.markup = {
        id: 0,
        markup_on: '',
        markup_rate: 0
      };
    }else{
      this.markup = markup;
    }
  }
}
