export class hotels {
  id?: number = 0;
  country_id?: number;
  state_id?: number;
  location_id?: number;
  hotel_name?: any;
  star?: number;
  hotel_amenity_ids? = [];
  room_amenity_ids? = [];
  longitude?: any;
  latitude?: any;
  description?: any;
  address?: any;
  place_name?: any;
  pin_code?: any;
  contact_number?: number;
  check_in_time?: any;
  check_out_time?: any;
  email?: any;
  website?: any;
  bathroom?: any;
  media_technology?: any;
  food_drink?: any;
  internet_access?: any;
  clearing_service?: any;
  language_speak?: any;
  near_by_attraction?: any;
  property_rules?: any;
}

export class hotel_rate {
  id?: number = 0;
  hotel_id?: number;
  room_type?: any;
  meal_plan_id?: number;
  double_bed?: number = 0;
  extra_bed?: number = 0;
  twin_bed?: number = 0;
  quard_bed?: number = 0;
  child_with_bed?: number = 0;
  child_without_bed?: number = 0;
  single_bed?: number = 0;
  valid_from?: any;
  valid_to?: any;
  child_age?: any;
  bb?: number = 0;
  lunch?: number = 0;
  dinner?: number = 0;
  luxury_tax?: number = 0;
  other_tax?: number = 0;
  currency_type?: any;
  hgst?: number = 0;
  active?: any = 'N';
  cancellation_policy?: any;
  visibility?: any = 'N';
  supplier_id?: number;
  rate_type?:any;
  child_age_from?: number;
  child_age_to?: number;
  days?:any;
}
