import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EndPointService } from 'src/app/services/end-point.service';
import { ResponseToasterService } from 'src/app/services/responseStatus/response-toaster.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  room_type?: any;
  amenity?: any;
  image_class?: any;
  room_amenity?: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogComponent>,
    private endpoint: EndPointService,
    public toastr: ResponseToasterService
  ) { }

  ngOnInit(): void {
    switch(this.data.type){
      case 'room_type':
        this.room_type = this.data.value;
      break;
      case 'amenity':
        this.amenity = this.data.value;
        this.image_class = this.data.class;
      break;
      case 'room-amenity':
        this.room_amenity = this.data.value;
        this.image_class = this.data.class;
      break;
    }

  }
  onUpdateCreate(type){
    switch(type){
      case 'room_type':
        this.createUpdateHotelRoomType();
      break;
      case 'amenity':
        this.createUpdateAmenity();
      break;
      case 'room-amenity':
        this.createUpdateRoomAmenity();
      break;
    }
  }

  createUpdateHotelRoomType(){
    if(this.room_type.trim() != "" && this.room_type != undefined){
      let formData = { room_type: this.room_type }
      formData["id"] = (this.data.mode === 'update') ? this.data.id : 0;

        this.endpoint.postData('hotels/room-types', formData, false).subscribe((resp) => {
          if (resp.status && resp.message == 'success') {
            this.dialogRef.close({data: resp.status});
            this.toastr.successResponse('Room type added');
          }
        },(err) => {
            this.toastr.errorResponse('Oops <br>Error while creating room type.', true);
        });
      }
  }

  createUpdateAmenity(){
    if(this.amenity.trim() != "" && this.amenity != undefined){
      let formData = { amenity: this.amenity, image_class: this.image_class }
      formData["id"] = (this.data.mode === 'update') ? this.data.id : 0;

        this.endpoint.postData('hotels/amenities', formData, false).subscribe((resp) => {
          if (resp.status && resp.message == 'success') {
            let data = {
              'status': true,
              'data': resp.data
            }
            this.dialogRef.close({data: data});
            this.toastr.successResponse('Amenity '+this.data.mode+'d');
          }else{
            this.toastr.errorResponse(resp.message , true);
          }
        },(err) => {
            this.toastr.errorResponse('Oops <br>Error while amenity '+this.data.mode , true);
        });
      }
  }

  createUpdateRoomAmenity(){
    if(this.room_amenity.trim() != "" && this.room_amenity != undefined){
      let formData = { amenity: this.room_amenity, image_class: this.image_class }
      formData["id"] = (this.data.mode === 'update') ? this.data.id : 0;

        this.endpoint.postData('hotels/room/amenities', formData, false).subscribe((resp) => {
          if (resp.status && resp.message == 'success') {
            let data = {
              'status': true,
              'data': resp.data
            }
            this.dialogRef.close({data: data});
            this.toastr.successResponse('Room amenity '+this.data.mode+'d');
          }else{
            this.toastr.errorResponse(resp.message , true);
          }
        },(err) => {
            this.toastr.errorResponse('Oops <br>Error while room amenity '+this.data.mode , true);
        });
      }
  }
}
