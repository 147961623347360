export class user {
  name?:string;
  email?: string;
  username?: string;
  avatar?: string;
  role?: number

  id: number;
  agency_id: number;
  password: any;
  status: number;
  approved_by: any;
  full_address?: any;
  salary?:number = 0;
  aadhaar_number?:number = 0;
  pan_number?:any;
  account_number?:number;
  bank_name?:any;
  ifs_code?:any;
  branch_name?:any;
  vehicle_no: any;
  vehicle_use?: any;
  company_number?: number;
  personal_number?: any;
  user_location?: any;
}

export class userRole {
  public static 1: string = 'ADMINISTRATOR';
  public static 2: string = 'OPERATION';
  public static 3: string = 'COORDINATOR';
  public static 4: string = 'ACCOUNT';
  public static 5: string = 'RESERVATION';
  public static 6: string = 'WEB_TEAM'
}
