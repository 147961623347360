import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = [];
  showSubSubMenu: boolean = false;
  panelOpenState = false;

  mouseenter(isExpanded, i) {
    if (!isExpanded) {
      this.isShowing[i] = true;
    }else{
      this.isShowing[i] = false;
    }
  }


  constructor() { }

  ngOnInit(): void {
  }

}
