import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from  '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviourService } from 'src/app/services/behaviour.service';
import { EndPointService } from 'src/app/services/end-point.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  progress_loader = false;
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  constructor(private endpoint:EndPointService, private navigate: Router, public toaster: ToastrService, private behaviour: BehaviourService ) { }

  ngOnInit(): void {
    this.behaviour.setHeader(false);
  }

  get f(){
    return this.loginForm.controls;
  }

  getLogIn(){

  this.progress_loader = true;
    this.endpoint.postData('users/login', this.loginForm.value, true).subscribe(resp => {
      if (resp.status && resp.message == "success") {
          this.progress_loader = false;
          localStorage.setItem('trip-zolo-logged-user', JSON.stringify(resp.data));
          this.navigate.navigateByUrl('/dashboard');
          return;
      }
      this.progress_loader = false;
      this.toaster.error('Invalid credentials','Error');
    }, err => {
      this.toaster.error('Oops :)\nError while login.', 'Error');
    });
  }
}
