import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EndPointService } from 'src/app/services/end-point.service';
import { ResponseToasterService } from 'src/app/services/responseStatus/response-toaster.service';

@Component({
  selector: 'app-place-dialog',
  templateUrl: './place-dialog.component.html',
  styleUrls: ['./place-dialog.component.css']
})
export class PlaceDialogComponent implements OnInit {
  place_name?: any
  code?: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PlaceDialogComponent>,
    private endpoint: EndPointService,
    public toastr: ResponseToasterService
  ) {
    this.place_name = (data.params.add_new === true)? '' : data.params.name;
    console.log(data);
  }

  ngOnInit(): void {
  }

  updatePlace(){
    let url = '';
    if(this.place_name.trim() != "" && this.place_name != undefined){
      let formData = {};
      switch(this.data.placeType){
        case 'country':
          url = 'place/countries';
          formData = {
            id: this.data.params.id,
            name: this.place_name
          }
        break;
        case 'state':
          url = 'place/states';
          formData = {
            id: this.data.params.id,
            state_name: this.place_name
          }

          if(this.data.params.add_new){
            formData['add'] = this.data.params.add_new;
            formData['country_id'] = this.data.params.country_id;
          }
        break;
        case 'location':
          url = 'place/locations';
          formData = {
            id: this.data.params.id,
            location_name: this.place_name
          }
          if(this.data.params.add_new){
            formData['add'] = this.data.params.add_new;
            formData['state_id'] = this.data.params.state_id;
          }
        break;
      }


        this.endpoint.postData(url, formData, false).subscribe((resp) => {
          if (resp.status && resp.message == 'success') {
            this.dialogRef.close({data: resp.status});
            this.toastr.successResponse(this.data.placeType+ (this.data.params.add_new === true ?' added' : ' updated'));
            this.dialogRef.close({data: {id: this.data.params.id, name:this.place_name}});
          }
        },(err) => {
            this.toastr.errorResponse('Oops <br>Error while updating '+this.data.placeType, true);
        });
      }
  }
}
