import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EndPointService } from 'src/app/services/end-point.service';
import { ResponseToasterService } from 'src/app/services/responseStatus/response-toaster.service';
import { DeleteDialogComponent } from 'src/app/utility/delete-dialog/delete-dialog.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import * as _moment from "moment";

const moment =  _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY"
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DiscountComponent implements OnInit {
  markup = {
    id: 0,
    code:'',
    type: 0,
    discount: 0,
    valid_from: '',
    valid_to: '',
    description:''
  }
  markup_types = [
    {id: 1, name: 'package'},
    {id: 2, name: 'hotel'},
  ]

  markups = [];
  markup_loader:boolean = false;
  constructor(
    private endpoint: EndPointService,
    public toaster: ResponseToasterService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getMarkups();
  }


  getMarkups(){
    this.endpoint.getData('discounts', {}).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
          this.markup_loader = false;
          this.markups = resp.data;
        }
      },
      (err) => {
        this.toaster.errorResponse('Oops.. <br>Error while fetching discounts.', true);
      }
    );
  }

  createUpdateMarkup(){
    this.endpoint.postData('discounts', this.markup, false).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
        if(this.markup.id == 0){
          this.markups.push(resp.data);
        }
          this.toaster.successResponse("Success");
          return;
        }
        this.toaster.errorResponse(resp.message);
      },
      (err) => {
        this.toaster.errorResponse('Oops.. <br>Error while fetching discounts.', true);
      }
    );
  }

  deleteMarkup(markup_id){
    let dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(response => {
      if(response == true){
        this.endpoint.deleteData('discounts/'+markup_id).subscribe(resp => {
          if (resp.status && resp.message == 'success') {
              let index = this.markups.findIndex(htl => htl.id == markup_id);
              this.markups.splice(index, 1);
              this.toaster.successResponse('Deleted successfully', true);
            }
          },
          (err) => {
            this.toaster.errorResponse('Oops.. <br>Error while deleting discounts.', true);
          }
        );
      }
    })
  }


  dateFormater(date){
    return date.getFullYear()
    +'-'+
    (('0' + (date.getMonth() + 1)).slice(-2))
    +'-'+
    (('0' + (date.getDate())).slice(-2));
  }

  valueChanged(event, slug){
    switch(slug){
    case 'from':
      this.markup.valid_from = this.dateFormater(event._d);
      break;
      case 'to':
      this.markup.valid_to = this.dateFormater(event._d);
      break;
    }
  }

  onSelect(markup){
    this.markup = markup;
  }

  resetForm(){
    this.markup =  {
      id: 0,
      code:'',
      type: 0,
      discount: 0,
      valid_from: '',
      valid_to: '',
      description:''
    };
  }
}
