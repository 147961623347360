import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './operations/dashboard/dashboard.component';
import { EnquiryFormComponent } from './operations/enquiry/enquiry-form/enquiry-form.component';
import { EnquiryListComponent } from './operations/enquiry/enquiry-list/enquiry-list.component';
import { LoginComponent } from './operations/login/login.component';
import { ProfileComponent } from './operations/users/profile/profile.component';

const routes: Routes = [
    {path: '', component: LoginComponent},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'profile', component: ProfileComponent},
    // {path: 'form', component: EnquiryFormComponent},
    // {path: 'enquiries', component: EnquiryListComponent},
    {path: 'mice', loadChildren: ()=>import('./operations/operations.module').then(mod => mod.OperationsModule)},
    {path: 'mice/accounts', loadChildren: ()=>import('./operations/accounts/accounts.module').then(mod => mod.AccountsModule)},
    // {path: 'costing', loadChildren: ()=>import('./operations/costing/costing.module').then(mod => mod.CostingModule)},
    {path: 'manage/package', loadChildren: ()=>import('./admin/package/package.module').then(mod => mod.PackageModule)},
    {path: 'manage/hotel', loadChildren: ()=>import('./admin/hotel/hotel.module').then(mod => mod.HotelModule)},
    {path: 'manage/place', loadChildren: ()=>import('./admin/place/place.module').then(mod => mod.PlaceModule)},
    {path: 'manage/markup', loadChildren: ()=>import('./admin/markup/markup.module').then(mod => mod.MarkupModule)},
    {path: 'manage/discount', loadChildren: ()=>import('./admin/discount/discount.module').then(mod => mod.DiscountModule)},
    {path: 'manage/gst', loadChildren: ()=>import('./admin/gst/gst.module').then(mod => mod.GstModule)},
    {path: 'manage/supplier', loadChildren: ()=>import('./admin/supplier/supplier.module').then(mod => mod.SupplierModule)},
    {path: 'csv', loadChildren: ()=>import('./admin/csv-upload/csv-upload.module').then(mod => mod.CsvUploadModule)},
    {path: 'agency', loadChildren: ()=>import('./agency/agency.module').then(mod => mod.AgencyModule)}
  ];

  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }
