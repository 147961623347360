import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './utility/header/header.component';
import { FooterComponent } from './utility/footer/footer.component';
import { LoginComponent } from './operations/login/login.component';
import { DashboardComponent } from './operations/dashboard/dashboard.component';
import { AvatarComponent } from './operations/users/avatar/avatar.component';
import { ProfileComponent } from './operations/users/profile/profile.component';
import { EnquiryFormComponent } from './operations/enquiry/enquiry-form/enquiry-form.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ToastrModule } from 'ngx-toastr';
import { EnquiryListComponent } from './operations/enquiry/enquiry-list/enquiry-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidenavListComponent } from './utility/sidenav-list/sidenav-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HotelComponent } from './admin/hotel/hotel/hotel.component';
import { PlaceComponent } from './admin/place/place/place.component';
import { PlaceDialogComponent } from './admin/place/place-dialog/place-dialog.component';
import { DeleteDialogComponent } from './utility/delete-dialog/delete-dialog.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { MarkupComponent } from './admin/markup/markup.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DiscountComponent } from './admin/discount/discount.component';
import { GstComponent } from './admin/gst/gst.component';
import { SupplierComponent } from './admin/supplier/supplier.component';
import {DatePipe} from '@angular/common';
import { MiceMarkUpComponent } from './mice/mice-mark-up/mice-mark-up.component';
import { UserPermissionsComponent } from './admin/user/user-permissions/user-permissions.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    AvatarComponent,
    ProfileComponent,
    // EnquiryFormComponent,
    // EnquiryListComponent,
    SidenavListComponent,
    HotelComponent,
    PlaceComponent,
    PlaceDialogComponent,
    DeleteDialogComponent,
    MarkupComponent,
    DiscountComponent,
    GstComponent,
    SupplierComponent,
    MiceMarkUpComponent,
    UserPermissionsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatCardModule,
    MatListModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatExpansionModule,
    MatProgressBarModule,
    CKEditorModule,
    MatDatepickerModule
  ],
  exports:[
  ],
  providers: [DatePipe],
  // providers: [
  // {
  //   provide: LocationStrategy, useClass: HashLocationStrategy
  // }
  // ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
