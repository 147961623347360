import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ResponseToasterService {

  constructor(
    public Toast: ToastrService
  ) { }

  successResponse(message, enableHtml = false){
    this.Toast.success(message, 'Success', {enableHtml: enableHtml, closeButton: true});
  }

  errorResponse(message, enableHtml = false){
    this.Toast.error(message, 'Error', {enableHtml: enableHtml, closeButton: true});
  }

  infoResponse(message, enableHtml = false){
    this.Toast.info(message, 'Info', {enableHtml: enableHtml, closeButton: true});
  }
}
