import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { userRole } from 'src/app/models/user_details';
import { AuthService } from 'src/app/services/auth-user/auth.service';
import { BehaviourService } from 'src/app/services/behaviour.service';
import { EndPointService } from 'src/app/services/end-point.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
@Output() public sidenavToggle = new EventEmitter();
  logged_in_user: any;
  visible_header: boolean = true;
  user_role = userRole;

  constructor(
    private navctrl:Router,
    private endpoint: EndPointService,
    private location: Location,
    private behaviour: BehaviourService,
    public AuthService: AuthService,
  ) {
    navctrl.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.visible_header = (this.location.path() !== "") ? true : false;
        if(this.visible_header){
          this.resetUserCredentials();
        }
      }
    });
  }


  ngOnInit(): void {
    this.navctrl.events.subscribe(val => {
    // console.log(this.location.path().split('/')[2]);
      if(this.location.path().split('/')[2] == 'pro-invoice' || this.location.path().split('/')[2] == 'invoice'  || this.location.path().split('/')[2] == 'final-invoice'){
        this.visible_header = false;
        return;
      }
    });
  }


  resetUserCredentials(){
    this.logged_in_user = JSON.parse(localStorage.getItem('trip-zolo-logged-user'));
    console.log(this.logged_in_user);
      this.endpoint.getData('users/current-user', {}).subscribe(resp => {
        if (resp.status && resp.message == "success") {

            let update_logger = {
              'avatar': resp.data?.avatar,
              'email': resp.data?.email,
              'role': resp.data?.role,
              'token': this.logged_in_user.token,
              'user_id': resp.data?.id,
              'username': resp.data?.username,
              'name': resp.data?.name,
          };
          localStorage.setItem('trip-zolo-logged-user',JSON.stringify(update_logger));
        }
      }, err => {
        alert('Oops :)\nError while fetching user.');
      });
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit()
  }

  viewProfile(){
    this.navctrl.navigateByUrl("profile")
    // window.location.href = "profile";
  }

  logout(){
    localStorage.removeItem('trip-zolo-logged-user');
    window.location.href = "/";
    // this.navigate.navigateByUrl('/');
  }
}
