import { Component, OnInit } from '@angular/core';
import { user } from 'src/app/models/user_details';
import { FormBuilder, FormGroup, Validators, FormControl } from  '@angular/forms';
import { EndPointService } from 'src/app/services/end-point.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user_detail = new user();
  profileForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    username: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private endpoint: EndPointService) { }

  ngOnInit(): void {
    this.endpoint.getData('users', {}).subscribe(resp => {
      if (resp.status && resp.message == "success") {
        this.user_detail = resp.data;
      }
    }, err => {
      alert('Oops :)\nError while submitting.');
    });
  }

  get proform(){
    return this.profileForm.controls;
  }

  updateProfile(){}

}
