import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EndPointService {
  // apiUrl = 'http://localhost:8080/';
  apiUrl = 'https://tripzolo.online/';

  constructor(private http: HttpClient, private route: Router) {}


  postData(endpoint, data, loader):Observable<any>{
    let token = "login";
    if(endpoint !== 'users/login' && endpoint !== 'users'){
      token = JSON.parse(localStorage.getItem('trip-zolo-logged-user')).token;
    }
    let headers = new HttpHeaders({
      // 'Content-Type': 'application/json;charset=UTF-8',
      // 'Authorization': 'Bearer '+ token,
        'Authorization': 'Bearer '+ token,
    });



    return new Observable(observe => {
      this.http.post(this.apiUrl+endpoint, data, {headers: headers}).subscribe(resp => {
        observe.next(resp);
      },err => {
        observe.next(err);
      });
    })
  }

  getData(endpoint, data):Observable<any>{
    let token = JSON.parse(localStorage.getItem('trip-zolo-logged-user')).token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer '+ token,
    });
    return new Observable(observe => {
      this.http.get(this.apiUrl+endpoint, {headers: headers, params: data}).subscribe(resp => {
        observe.next(resp);
      },error => {
        if(error.status === 403){
          localStorage.removeItem('trip-zolo-logged-user');
          this.route.navigate(['/']);
        }
        observe.next(error);
      });
    });
  }

  putData(endpoint, data, loader):Observable<any>{
    let token = JSON.parse(localStorage.getItem('trip-zolo-logged-user')).token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer '+ token,
    });
    return new Observable(observe => {
      this.http.put(this.apiUrl+endpoint, data, {headers: headers}).subscribe(resp => {
        observe.next(resp);
      },err => {
        observe.next(err);
      });
    })
  }

  deleteData(endpoint):Observable<any>{
    let token = JSON.parse(localStorage.getItem('trip-zolo-logged-user')).token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer '+ token,
    });
    return new Observable(observe => {
      this.http.delete(this.apiUrl+endpoint, {headers: headers}).subscribe(resp => {
        observe.next(resp);
      },err => {
        observe.next(err);
      });
    })
  }
}
