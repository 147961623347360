import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { user } from './models/user_details';
import { BehaviourService } from './services/behaviour.service';
import { EndPointService } from './services/end-point.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'tripzolo-admin';
  user = new user()
;  constructor(
    private navigate:Router,
    private location: Location,
    private endpoint: EndPointService,
    private behaviour: BehaviourService
  ){}

  ngAfterViewInit(){

  }

  ngOnInit(): void {
    if(this.location.path() !== ""){
      this.behaviour.setHeader(true);
    }
    let auth_user = JSON.parse(localStorage.getItem('trip-zolo-logged-user'));
    if(auth_user == null){
      this.navigate.navigateByUrl('/');
      return;
    }else{
      if(this.location.path() === ""){
        this.navigate.navigateByUrl('/dashboard');
        // window.location.href = "dashboard";
      }
    }
  }
}
