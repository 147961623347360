import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { suppliers } from 'src/app/models/supplier';
import { EndPointService } from 'src/app/services/end-point.service';
import { ResponseToasterService } from 'src/app/services/responseStatus/response-toaster.service';
import { DeleteDialogComponent } from 'src/app/utility/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css']
})
export class SupplierComponent implements OnInit {
  supplier = new suppliers();
  suppliers = [];
  supplier_loader: boolean = false;
  location_data =[];
  states = [];
  constructor(
    private endpoint: EndPointService,
    public toaster: ResponseToasterService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  this.getSupplies();
    this.getStates(101);
  }

  getSupplies(){
    this.endpoint.getData('suppliers', {}).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
          this.supplier_loader = false;
          this.suppliers = resp.data;
        }
      },
      (err) => {
        this.toaster.errorResponse('Oops.. <br>Error while fetching supplier.', true);
      }
    );
  }

  createUpdateSupplier(){
    this.endpoint.postData('suppliers', this.supplier, false).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
        if(this.supplier.id == 0){
          this.suppliers.push(resp.data);
        }
          this.toaster.successResponse("Success");
          return;
        }
        this.toaster.errorResponse(resp.message);
      },
      (err) => {
        this.toaster.errorResponse('Oops.. <br>Error while fetching supplier details.', true);
      }
    );
  }

  resetForm(){
    this.supplier = new suppliers();
  }


  deleteSupplier(supplier_id){
    let dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe(response => {
      if(response == true){
        this.endpoint.deleteData('suppliers/'+supplier_id).subscribe(resp => {
          if (resp.status && resp.message == 'success') {
              let index = this.suppliers.findIndex(htl => htl.id == supplier_id);
              this.suppliers.splice(index, 1);
              this.toaster.successResponse('Deleted successfully', true);
            }
          },
          (err) => {
            this.toaster.errorResponse('Oops.. <br>Error while deleting supplier.', true);
          }
        );
      }
    })
  }


  getStates(country_id){
    this.endpoint.getData('place/countries/states/'+country_id, {}).subscribe((resp) => {
        if (resp.status && resp.message == 'success') {
          this.states = resp.data;
        }
      },(err) => {
        this.toaster.errorResponse('Oops <br>Error while fetching states.')
      }
    );
  }

  onStateChange(state) {
    this.getLocations(state.value);
  }

  getLocations(state_id){
    this.endpoint.getData('place/state/locations/' + state_id, {}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.location_data = resp.data;
        }
      },(err) => {
        this.toaster.errorResponse('Oops <br>nError while fetching locations.')
      }
  );
  }

  onSelect(supplier){
    this.supplier = supplier;
    this.getLocations(this.supplier.state_id);
  }
}
