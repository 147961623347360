import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { hotels } from 'src/app/models/hotel';
import { EndPointService } from 'src/app/services/end-point.service';
import { ResponseToasterService } from 'src/app/services/responseStatus/response-toaster.service';
import { DeleteDialogComponent } from 'src/app/utility/delete-dialog/delete-dialog.component';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.css'],
})
export class HotelComponent implements OnInit {
  countries: any;
  states: any;
  locations: any;
  hotel_loader: boolean = false;
  hotels = new hotels();
  uploadedImageDetail = [];
  img: any;
  amenities = [];
  roomAmenities = [];
  duration = true;
  imageurls = [];
  replaceSliderImages = ['','','',''];
  submitBtn: boolean = false;

  constructor(
    private endpoint: EndPointService,
    private toastr: ResponseToasterService,
    private route: ActivatedRoute,
    private navigate:Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.hotels.id = Number(this.route.snapshot.paramMap.get('hotel_id'));
    if(this.hotels.id > 0){
      this.hotel_loader = true;
      this.getHotelDetails(this.hotels.id);
    }else{
      this.getAmenities();
      // this.getRoomAmenities();
    }
    this.getCountries();

  }

  getHotelDetails(hotel_id){
    this.endpoint.getData('hotels/'+hotel_id, {}).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
          this.hotels = resp.data;
          if(this.hotels['room_inclusion_details'] != null){
          this.hotels['room_inclusion_details'].forEach((detail, index) => {
            this.hotels[Object.keys(detail).toLocaleString().toLowerCase().replace(" ", "_")] = Object.values(detail).toLocaleString();
          });
        }

          this.imageurls = resp.data.images;
          this.getStates(this.hotels.country_id);
          this.getLocations(this.hotels.state_id);
          this.getAmenities();
          // this.getRoomAmenities();
          this.hotel_loader = false;
        }
      },
      (err) => {
        this.toastr.errorResponse('Oops.. <br>Error while fetching hotel details.', true);
      }
    );
  }
  onCityTourItinerarySelected(event){
    this.hotels.description = event.editor._.data;
  }

  getCountries(){
    this.endpoint.getData('place/countries', {}).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
          this.countries = resp.data;
        }
      },
      (err) => {
        this.toastr.errorResponse('Oops.. <br>Error while fetching countries.', true);
      }
    );
  }

  getAmenities(){
    this.endpoint.getData('hotels/list/amenities', {}).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
        if(this.hotels.hotel_amenity_ids !== undefined && this.hotels.hotel_amenity_ids !== null && this.hotels.hotel_amenity_ids.length > 0){
          resp.data.forEach((ame, index) => {

            let findIndex = this.hotels.hotel_amenity_ids.findIndex(id => id === ame.id);
            if(findIndex !== -1){
              resp.data[index]["checked"] = true;
            }else{
              resp.data[index]["checked"] = false;
            }
          });
        }
        this.amenities = resp.data;
        }
      },
      (err) => {
        this.toastr.errorResponse('Oops.. <br>Error while fetching amenities.', true);
      }
    );
  }



  onCountryChange(event){
  this.hotels.state_id = null;
    this.getStates(event.value);
  }

  getStates(country_id){
    this.endpoint.getData('place/countries/states/'+country_id, {}).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
          this.states = resp.data;
        }
      },
      (err) => {
        this.toastr.errorResponse('Oops.. <br>Error while fetching states.', true);
      }
    );
  }

  onStateChange(event){
    this.hotels.location_id = null;
    this.getLocations(event.value);
  }

  getLocations(state_id){
    this.endpoint.getData('place/countries/states/locations/'+state_id, {}).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
          this.locations = resp.data;
        }
      },
      (err) => {
        this.toastr.errorResponse('Oops.. <br>Error while fetching locations.', true);
      }
    );
  }

  // onSelectFile(event) {
  //   if (event.target.files && event.target.files[0]) {
  //     this.img = event.target.files;
  //     this.uploadedImageDetail = this.img;
  //     var reader = new FileReader();

  //     reader.onload = (event) => {
  //         this.hotels.image = event.target.result;
  //     }
  //     reader.readAsDataURL(event.target.files[0]);
  //   }
  // }

  amenityOnCheck(event, amenity_id){
    if(event.currentTarget.checked){
      if(this.hotels.hotel_amenity_ids === undefined || this.hotels.hotel_amenity_ids === null){
        this.hotels.hotel_amenity_ids = [];
      }
      this.hotels.hotel_amenity_ids.push(amenity_id);
      return;
    }
    let amenity_index = this.hotels.hotel_amenity_ids.findIndex(id => id === amenity_id);
    this.hotels.hotel_amenity_ids.splice(amenity_index, 1);
  }

  roomAmenityOnCheck(event, amenity_id){
    if(event.currentTarget.checked){
      if(this.hotels.room_amenity_ids === undefined || this.hotels.room_amenity_ids === null){
        this.hotels.room_amenity_ids = [];
      }
      this.hotels.room_amenity_ids.push(amenity_id);
      console.log(this.hotels.room_amenity_ids);
      return;
    }
    let amenity_index = this.hotels.room_amenity_ids.findIndex(id => id === amenity_id);
    this.hotels.room_amenity_ids.splice(amenity_index, 1);
    console.log(this.hotels.room_amenity_ids);
  }

  addUpdateHotelAmenity(mode, value = '', id = 0, class_name = ''){
    let dialogRef = this.dialog.open(DialogComponent, {data: {type:'amenity', mode:mode, value: value, id: id, class:class_name}});
    dialogRef.afterClosed().subscribe(resp => {
      if(resp !== undefined){
        if(resp.data.status){
          if(mode === 'update'){
           let index = this.amenities.findIndex(amenity => amenity.id === id);
           if(index >= 0){
            this.amenities[index] = resp.data.data
           }
           return;
          }
          this.amenities.push(resp.data.data);
        }
      }
    });
  }




  onSelectFile(event, index) {
    let img = event.target.files;
    let length = this.imageurls.length;
    let getIndex =  this.imageurls.findIndex((item, i) => i === index);
    if (img && img[0]) {
      var reader = new FileReader();
      if(getIndex < 0){
        reader.onload = (event: any) => {
          this.imageurls.push({ path: event.target.result});
        }
        if(this.hotels.id > 0){
          this.replaceSliderImages[(length != 4 && length <= index) ? length : index] = '1';
        }
        img['potision'] = index;
        this.uploadedImageDetail.push(img);
      }else{
        reader.onload = (event: any) => {
          this.imageurls[index] = { path: event.target.result};
        }
        if(this.hotels.id > 0){
          this.replaceSliderImages[(length != 4 && length <= index) ? length : index] = '1';
        }
        img['potision'] = index;
        this.uploadedImageDetail[index] = img;
      }
      reader.readAsDataURL(img[0]);
    }
    console.log(this.uploadedImageDetail);
  }

  removeSliderImage(index){
    this.imageurls.splice(index, 1);
    this.uploadedImageDetail.splice(index, 1);
    this.replaceSliderImages[index] = '0';
    console.log(this.uploadedImageDetail);
  }

  onSubmitHotel(){
    // const formData = new FormData();
    // let formData = this.hotels;
    // formData.append('id', String(this.hotels.id));
    // formData.append('country_id', String(this.hotels.country_id));
    // formData.append('state_id', String(this.hotels.state_id));
    // formData.append('location_id', String(this.hotels.location_id));
    // formData.append('hotel_name', String(this.hotels.hotel_name));
    // formData.append('star', String(this.hotels.star));
    // formData.append('hotel_amenity_ids', this.hotels.hotel_amenity_ids);
    // formData.append('room_amenity_ids', this.hotels.room_amenity_ids);
    // formData.append('latitude', (this.hotels.latitude == null)?'':this.hotels.latitude);
    // formData.append('longitude', (this.hotels.longitude == null)?'':this.hotels.longitude);
    // formData.append('description', (this.hotels.description == null)?'':this.hotels.description);

    // if(this.uploadedImageDetail.length > 0){
      // formData.append('hotel_image', this.img[0], this.img[0].name);
      // this.toastr.infoResponse('Please upload image.');
      // return;
    // }
    // else{
    //   formData.append('hotel_image', this.img[0], this.img[0].name);
    // }

    this.hotels["room_inclusion_details"] = [
      {'Bathroom': this.hotels.bathroom},
      {'Media Technology': this.hotels.media_technology},
      {'Food Drink': this.hotels.food_drink},
      {'Internet Access': this.hotels.internet_access},
      {'Clearing Service': this.hotels.clearing_service},
      {'Language Speak': this.hotels.language_speak},
    ]

    this.endpoint.postData('hotels', this.hotels, false).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
          this.toastr.successResponse((this.hotels.id > 0 ? 'Updated' : 'Saved') + " successfully");
          this.uploadImages(resp.data.id);
           return;
        }
        this.toastr.errorResponse('Oops.. <br>'+resp.message, true);
      },
      (err) => {
        this.toastr.errorResponse('Oops.. <br>Error while create hotel.', true);
      }
    );
  }

  uploadImages(hotel_id){
    const formData = new FormData();
    this.uploadedImageDetail.map((slider, index) => {
      if (slider != null) {
        formData.append("images[]", slider[0], slider[0].name);
      }
    });
    formData.append("replace_slider_images", JSON.stringify(this.replaceSliderImages));
    this.submitBtn = false;
    this.endpoint.postData("hotels/"+hotel_id+"/images", formData, false).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.toastr.successResponse("Images successfully uploaded");
        }
        if(this.hotels.id === 0){
          this.navigate.navigate(['manage/hotel/update/', hotel_id]);
         }
         this.replaceSliderImages = ['','','',''];
      },
      (err) => {
        this.submitBtn = true;
        this.toastr.errorResponse('Oops <br>Error while uploading images' , true);
      }
    );
  }
}
