import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { user } from 'src/app/models/user_details';
import { BehaviourService } from 'src/app/services/behaviour.service';
import { EndPointService } from 'src/app/services/end-point.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  enquiries: any;
  limitEnquiryView = 6;
  user = new user();
  adminAccess = true;
  enquityModel: boolean = false;

  constructor(private endpoint: EndPointService,private behaviour: BehaviourService) {}

  ngOnInit(): void {
    this.behaviour.setHeader(true);
    // this.loadScript('../../../lib/jquery.flot/jquery.flot.js');
    // this.loadScript('../../../lib/jquery.flot/jquery.flot.stack.js');
    // this.loadScript('../../../lib/jquery.flot/jquery.flot.resize.js');
    // this.loadScript('../../../lib/chart.js/Chart.bundle.min.js');
    // this.loadScript('../../../assets/js/dashforge.sampledata.js');
    // this.loadScript('../../../assets/js/dashboard-one.js');

    this.adminAccess = (JSON.parse(localStorage.getItem('trip-zolo-logged-user')).role === 1) ?  true : false;
      this.endpoint.getData('enquiries/dashboard/' + this.limitEnquiryView, {}).subscribe(resp => {
        if (resp.status && resp.message == "success") {
          this.enquiries = resp.data
        }
      }, err => {
        alert('Oops :)\nError while fetching enquiries.');
      });
  }

  openCloseEnquiryModel(status){
    if(status){
      this.enquityModel = true;
      return;
    }
    this.enquityModel = false;
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}

