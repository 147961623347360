export class suppliers {
  id?:number = 0
  supplier_name?: any;
  state_id?: number;
  city_id?: number;
  address?: any;
  primary_contact_name?: any;
  primary_email?: any;
  primary_contact?: any;
  secondary_contact_name?: any;
  secondary_email?: any;
  secondary_contact?: any;
}
